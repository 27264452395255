import projectOne from "../assets/project-1.png";
import projectTwo from "../assets/project-2.png";
import projectThree from "../assets/project-3.png";

const projects = {
  1: {
    title: "Rock-Vin cafe App",
    image: projectOne,
    description: (
      <>
        <p>
        This project aims to develop a mobile application for Rock-Vin café and surf inn Midigma, a popular destination for tourists and locals in Sri Lanka. 
        The application will be built using Flutter Framework, which allows for cross-platform compatibility and fast development.
        </p>
      </>
    ),
    github: "https://github.com/Saliya99/rock_vin_cafe_app",
    demo: "https://netlify.com",
  },
  2: {
    title: "Multiuser Chat App",
    image: projectTwo,
    description: (
      <>
        <p>
        This project aims to develop a multiuser chat application using advanced programming concepts in Java. 
        The application will be developed using Java NetBeans IDE 8.2, GitHub Desktop, XAMPP Server, and MYSQL J Connector as the main tools.
        </p>
      </>
    ),
    github: "https://github.com/Saliya99/ChatApp",
    demo: "https://netlify.com",
  },
  3: {
    title: "Trading Bot",
    image: projectThree,
    description: (
      <>
        <p>
        This project aims to develop a bot for trading on the Deriv platform, which is an online trading service. 
        The bot will be created using Blockly, a visual programming language that allows users to create programs by dragging and dropping blocks. 
        The bot will use XML to store and exchange data, such as the trading parameters, the market conditions, and the bot's performance.
        </p>
      </>
    ),
    github: "https://github.com/Saliya99/Deriv_Bot",
    demo: "https://netlify.com",
  },
};

export default projects;
